import * as React from 'react'

export interface DelayedProps {
  /**
   * The delay in milliseconds before the component is rendered.
   *
   * @default 300
   */
  delay?: number

  /**
   * Delay in milliseconds before rendering timeout state.
   */
  timeout?: number

  /**
   * Throw on timeout (suspense)
   */
  throwOnTimeout?: boolean

  /**
   * Component visible during the delay.
   * If rendering text, wrap it in a `span` to prevent error — see PR#608
   */
  delayComponent?: React.ReactNode

  /**
   * Component visible when timeout is over.
   * If rendering text, wrap it in a `span` to prevent error — see PR#608
   */
  timeoutComponent?: React.ReactNode

  /**
   * Component visible when delay is over.
   * If rendering text, wrap it in a `span` to prevent error — see PR#608
   */
  children?: React.ReactNode
}

export function Delayed({
  delay = 300,
  timeout = 0,
  throwOnTimeout = false,
  delayComponent,
  timeoutComponent,
  children,
}: DelayedProps) {
  const [visible, setVisible] = React.useState(false)
  const [hasTimedOut, setHasTimedOut] = React.useState(false)

  React.useEffect(() => {
    const visibilityTimeout = window.setTimeout(() => setVisible(true), delay)

    let failedTimeout: number
    if (timeout > 0) {
      failedTimeout = window.setTimeout(() => {
        setHasTimedOut(true)
        if (throwOnTimeout) throw new Error()
      }, timeout)
    }

    return () => {
      clearTimeout(visibilityTimeout)
      clearTimeout(failedTimeout)
    }
  }, [delay, timeout, throwOnTimeout])

  if (!visible) return <>{delayComponent}</>

  if (hasTimedOut) {
    return <>{timeoutComponent}</>
  }

  return <>{children}</>
}
