import classnames from 'classnames'
import React from 'react'

import { useToolbarContext } from './hooks'
import { ToolbarDirection, ToolbarTheme } from './interfaces'

type ToolbarDividerProps = {
  className?: string
}

export function ToolbarDivider({ className }: ToolbarDividerProps) {
  const { direction, theme } = useToolbarContext()

  return (
    <div
      className={classnames(
        'bg-gray-100 dark:bg-gray-600',
        {
          'col-span-2 min-h-[1px] w-full': direction === ToolbarDirection.vertical,
          'mx-2 min-w-[1px]': direction === ToolbarDirection.horizontal,
          'my-2': direction === ToolbarDirection.vertical && theme === ToolbarTheme.alternative,
          'h-full': direction === ToolbarDirection.horizontal && theme !== ToolbarTheme.alternative,
          'h-8': direction === ToolbarDirection.horizontal && theme === ToolbarTheme.alternative,
        },
        className
      )}
    />
  )
}
