export function parseJSONFromScript<T>(id: string, doc: Document = document): T | null {
  const el = doc.getElementById(id)
  const isValidElement = el && el.tagName === 'SCRIPT' && el.getAttribute('type') === 'application/json'

  if (!isValidElement) {
    window.Sentry?.captureMessage('parseJSONFromScript', {
      extra: {
        el: el ? 'yes' : 'no',
        tagName: el?.tagName,
        type: el?.getAttribute('type'),
      },
    })
  }

  if (!isValidElement) return null

  try {
    return JSON.parse(el.textContent!)
  } catch (e) {
    Sentry.captureException(e)
    return null
  }
}
