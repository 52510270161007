import { ContentType, apiRequest } from '../../../main/services/api'
import { MapSubtypeId, MapTypeId, PerfData } from '../types'

export function mapPerf(type: MapTypeId, subtype: MapSubtypeId) {
  return apiRequest<PerfData>(`/api/map_perf.ashx`, { query: { t: type, st: subtype } })
}

export function mapPerfScreener(query = window.location.search) {
  return apiRequest<PerfData>(`/api/map_perf_screener.ashx${query}`)
}

export interface UploadMapResponse {
  imgUrl: string
  shareUrl: string
}

export function uploadMap({ type, subtype, data }: { type: MapTypeId; subtype: MapSubtypeId; data: string }) {
  return apiRequest<UploadMapResponse>(`/publish_map_submit.ashx`, {
    method: 'POST',
    headers: {
      'Content-Type': ContentType.FormUrlEncoded,
    },
    body: new URLSearchParams({
      file: data.replace('data:image/png;base64,', ''),
      type,
      subtype,
    }),
  })
}
