import { Dispatcher } from 'flux'

import { PayloadSources } from './constants'

class AppDispatcher extends Dispatcher<any> {
  handleServerAction(action: any) {
    var payload = {
      source: PayloadSources.SERVER_ACTION,
      action: action,
    }
    //console.log(action, payload);
    this.dispatch(payload)
  }

  handleViewAction(action: any) {
    var payload = {
      source: PayloadSources.VIEW_ACTION,
      action: action,
    }
    //console.log(action, payload);
    this.dispatch(payload)
  }
}

const dispatcher = new AppDispatcher()
export default dispatcher
