import { PartialBy } from '../../types'
import { ToolbarButton } from './toolbar-button'
import { ToolbarGroup, ToolbarGroupProps } from './toolbar-group'

export function ToolbarItem({
  defaultItem,
  isLoading,
  isActive,
  isInAction,
  groupItems,
  groupTitle,
  onChange,
  useNativeSelect,
  disabled,
  disabledTooltip,
  rowId,
}: PartialBy<ToolbarGroupProps, 'groupItems'>) {
  const isItemActive = isActive && !disabled

  if (!!groupItems?.length) {
    return (
      <ToolbarGroup
        rowId={rowId}
        groupTitle={groupTitle}
        groupItems={groupItems}
        defaultItem={defaultItem}
        disabled={disabled}
        isActive={isItemActive}
        isLoading={isLoading}
        onChange={onChange}
        useNativeSelect={useNativeSelect}
        disabledTooltip={disabledTooltip}
      />
    )
  }

  return (
    <ToolbarButton
      rowId={rowId}
      data-testid={`toolbar-item-${defaultItem.id}`}
      item={defaultItem}
      active={isItemActive}
      isLoading={isLoading}
      isInAction={isInAction}
      disabled={disabled}
      disabledTooltip={disabledTooltip}
      onClick={() => {
        onChange(defaultItem.id, 'button')
      }}
    />
  )
}
