import { useCallback, useMemo } from 'react'

import { DraggablePicker, Interaction } from './draggable'
import { Pointer } from './pointer'
import { HSVA, hsvaToHEXString } from './util'

interface LightnessProps {
  color: HSVA
  onChange: (color: HSVA) => void
}

export function Alpha({ color, onChange }: LightnessProps) {
  const changeLightness = useCallback((pos: Interaction) => onChange({ ...color, a: 1 - pos.left }), [onChange, color])

  const gradientPreview = useMemo(() => {
    const gradientFrom = hsvaToHEXString({ ...color, a: 1 })
    const gradientTo = hsvaToHEXString({ ...color, a: 0 }, true)

    return `linear-gradient(90deg, ${gradientFrom}, ${gradientTo})`
    // Don’t recalculate when alpha changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color.h, color.s, color.v])

  return (
    <DraggablePicker onMove={changeLightness} onKey={changeLightness} aria-label="Alpha">
      <div className="flex h-4 overflow-hidden rounded-md bg-white bg-checkerboard bg-fixed">
        <div className="grow" style={{ backgroundImage: gradientPreview }} />
      </div>
      <Pointer top={0.5} left={1 - color.a} color={hsvaToHEXString(color, true)} />
    </DraggablePicker>
  )
}
