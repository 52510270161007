import * as Ariakit from '@ariakit/react'
import classnames from 'classnames'
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { isMobile } from '../../../app/shared/isMobile'
import { Button } from '../button'
import { Popover, PopoverTrigger, usePopoverState } from '../popover'
import { Alpha } from './alpha'
import { ColorPalette } from './color-palette'
import { HueSaturation } from './hue-saturation'
import { Lightness } from './lightness'
import { PickerInput } from './picker-input'
import * as colorUtil from './util'

export interface ColorPickerProps {
  /**
   * The selected color in HEX format (supports HEX8 for alpha)
   */
  color: string

  /**
   * Whether or not the user is able to select custom color
   *
   * @default true
   */
  canSelectCustom?: boolean

  /**
   * Whether or not the user is able to change alpha channel. Only applicable
   * when `canSelectCustom` is true
   *
   * @default true
   */
  canSelectAlpha?: boolean

  /**
   * Sets the output format for the color picker
   *
   * @default 'hex'
   */
  outputFormat?: 'hex' | 'rgba'

  /**
   * Callback when user changes color. Use the second parameter to do color space
   * conversions otherwise the color could differ due to rounding
   */
  onChange: (color: string, originalColor: colorUtil.HSVA) => void

  /**
   * Callback when user clicks on a palette item. Used to close popover
   */
  onPaletteItemClick?: () => void
}

export function ColorPicker({
  color,
  canSelectCustom = true,
  canSelectAlpha = true,
  outputFormat = 'hex',
  onChange,
  onPaletteItemClick,
}: ColorPickerProps) {
  const cachedColor = useRef(color)
  const [hsvaColor, setColor] = useState(colorUtil.getHSVAFromColor(color))
  const popover = Ariakit.usePopoverContext()
  const input = popover?.useState('disclosureElement')

  /**
   * Update state if the color from outside changed
   */
  useEffect(() => {
    const currentColor = colorUtil.removeHashSymbol(cachedColor.current)
    const newColor = colorUtil.removeHashSymbol(color)

    if (currentColor === newColor || !colorUtil.isValidColor(newColor)) return

    cachedColor.current = color
    setColor(colorUtil.getHSVAFromColor(color))
  }, [color])

  /**
   * Handle color change
   * 1. update cache so we can correctly check when outside color updates
   * 2. Update internal color representation
   * 3. call onChange with string color
   */
  const onChangeCallback = useCallback(
    (color: colorUtil.HSVA) => {
      const stringColor = colorUtil.stringifyHSVAColor(color, outputFormat, canSelectAlpha)
      cachedColor.current = stringColor
      onChange(stringColor, color)
      setColor(color)
    },
    [onChange, canSelectAlpha, outputFormat]
  )

  const onSetCustomCodeClick = useCallback(() => {
    const value = prompt('Enter a color code')?.trim()

    if (!value) return

    const isValid = colorUtil.isValidColor(value)

    if (!isValid) {
      return alert('The code is not a valid color')
    }

    onChangeCallback(colorUtil.getHSVAFromColor(value))
  }, [onChangeCallback])

  return (
    <div className="flex h-full w-full flex-col space-y-4 sm:space-y-2">
      {canSelectCustom && (
        <>
          {isMobile() && (
            <Button onClick={onSetCustomCodeClick} className="-mb-2 sm:mb-0" contentClass="text-center">
              Paste color code
            </Button>
          )}
          <HueSaturation color={hsvaColor} onChange={onChangeCallback} />
          <Lightness color={hsvaColor} onChange={onChangeCallback} />

          {canSelectAlpha && <Alpha color={hsvaColor} onChange={onChangeCallback} />}
        </>
      )}

      <div
        className={classnames({
          '-mx-2 border-t border-gray-200 px-2 pt-3 dark:border-gray-700 sm:pt-2': canSelectCustom,
        })}
      >
        <ColorPalette
          color={color}
          onChange={(_hex, orignalColor) => {
            input?.focus({ preventScroll: true })
            onPaletteItemClick?.()
            onChangeCallback({ ...orignalColor, a: hsvaColor.a })
          }}
        />
      </div>
    </div>
  )
}

interface InputColorPickerProps extends ColorPickerProps {
  /**
   * Label for the input
   */
  label?: ReactNode
  inputDataTestId?: string
}

export function InputColorPicker({ label, inputDataTestId, onChange, ...props }: InputColorPickerProps) {
  const popoverState = usePopoverState()
  const canSelectCustom = props.canSelectCustom !== false
  const isMobileDevice = isMobile()

  return (
    <>
      <PopoverTrigger
        state={popoverState}
        render={
          <PickerInput
            color={props.color}
            label={label}
            type={isMobileDevice || !canSelectCustom ? 'button' : 'text'}
            data-testid={inputDataTestId}
            onChange={onChange}
          />
        }
      />
      <Popover state={popoverState} hideOnInteractOutside aria-label="Color picker" backdrop={false}>
        <div
          className={classnames(
            'w-64 p-2',
            canSelectCustom && {
              'h-72': isMobileDevice,
              'h-64': !isMobileDevice,
            }
          )}
        >
          <ColorPicker
            {...props}
            onChange={onChange}
            onPaletteItemClick={() => {
              if (canSelectCustom) return
              popoverState.hide()
            }}
          />
        </div>
      </Popover>
    </>
  )
}
