export enum HPSignalInterval {
  intraday = 'intraday',
  daily = 'daily',
}

export const HP_SIGNAL_LABELS: Record<HPSignalInterval, string> = {
  [HPSignalInterval.intraday]: 'Intraday',
  [HPSignalInterval.daily]: 'Daily',
}
export const HP_SIGNAL_COOKIE = 'signalsInterval'
export const HP_SIGNAL_SWITCH_RENDER_SELECTOR = 'js-switches-root'
