import * as Ariakit from '@ariakit/react'
import classnames from 'classnames'
import * as React from 'react'

import { Button, ButtonHTMLProps, ButtonProps } from '../button'
import { Delayed } from '../delayed'
import { Icon } from '../icon'
import { Spinner } from '../spinner'
import { PropsWithAs } from '../types'
import { useToolbarContext } from './hooks'
import { IToolbarItem, ToolbarTheme } from './interfaces'

function getButtonThemeSettings({
  className,
  theme,
  isActive,
  isStretched,
  isInAction,
}: {
  className: string
  theme: ToolbarTheme
  isStretched: boolean
  isActive?: boolean
  isInAction?: boolean
}): Omit<ButtonHTMLProps, 'as'> & ButtonProps {
  const includeRedesign = FinvizSettings.hasRedesignEnabled
  switch (theme) {
    case ToolbarTheme.alternative:
      return {
        className: classnames(
          className,
          {
            grow: isStretched,
            'h-10 w-10': !isStretched && !includeRedesign,
            'animate-pulse': isInAction,
          },

          !includeRedesign && {
            'active:bg-white disabled:bg-white disabled:text-gray-300 disabled:hover:text-gray-300': true,
            'border border-white text-gray-500 hover:border-gray-100 hover:text-gray-900': !isActive,
            'border border-gray-900 text-gray-900': isActive,
          }
        ),
        theme: includeRedesign ? 'chipTransparent' : 'transparent',
        rounding: includeRedesign ? undefined : 'none',
        size: isStretched || includeRedesign ? undefined : 'none',
      }
    default:
      return {
        className: classnames(
          className,
          'border dark:disabled:bg-gray-800 dark:disabled:text-gray-600 disabled:text-gray-300 disabled:bg-white dark:disabled:hover:bg-gray-800 dark:disabled:hover:text-gray-600 disabled:hover:text-gray-300 disabled:hover:bg-white',
          {
            'animate-pulse': isInAction,
            'border-transparent text-gray-500 hover:bg-gray-200/30 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-500/30 dark:hover:text-gray-200':
              !isActive,
            'border-blue-400 bg-blue-50 text-gray-900 dark:bg-gray-700 dark:text-white': isActive,
          }
        ),
        theme: 'transparent',
        rounding: 'small',
      }
  }
}

export interface ToolbarButtonProps extends Omit<ButtonProps, 'theme'> {
  rowId?: number
  item: IToolbarItem
  disabledTooltip?: string
  isInAction?: boolean
  isLoading?: boolean
}

export const ToolbarButton = React.forwardRef(function ToolbarButton<TagType extends React.ElementType = 'button'>(
  { item, disabledTooltip, isInAction: isInActionProp, isLoading, ...props }: PropsWithAs<TagType> & ToolbarButtonProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const context = Ariakit.useMenuContext()
  const { theme, isStretched } = useToolbarContext()
  const isInAction = isInActionProp && item.iconAction !== undefined
  const buttonSettings = getButtonThemeSettings({
    className: props.className,
    theme,
    isStretched,
    isActive: props.active,
    isInAction: isInAction,
  })

  const getMainIcon = () => (
    <Icon name={(props.active && item.iconActive) || item.icon} width={32} className="shrink-0" />
  )

  return (
    <Ariakit.MenuItem
      {...props}
      // Ariakit defines the element to be a div, but we know we are rendering a button
      ref={ref as React.ForwardedRef<HTMLDivElement>}
      store={context?.menubar || undefined}
      blurOnHoverEnd
      render={
        <Button
          {...buttonSettings}
          className={buttonSettings.className}
          appearance={props.appearance ?? 'square'}
          title={props.disabled && disabledTooltip ? disabledTooltip : (props.active && item.titleActive) || item.title}
          size={
            buttonSettings.size ??
            (theme === ToolbarTheme.chipTransparent || FinvizSettings.hasRedesignEnabled ? 'medium' : 'large')
          }
        >
          {isLoading ? (
            <Delayed delay={100} delayComponent={getMainIcon()}>
              <Spinner width={16} />
            </Delayed>
          ) : (
            <>
              {props.children ??
                (isInAction ? (
                  <Delayed delay={100} delayComponent={getMainIcon()}>
                    <Icon name={item.iconAction} width={32} className="shrink-0" />
                  </Delayed>
                ) : (
                  getMainIcon()
                ))}
            </>
          )}
        </Button>
      }
    />
  )
})
