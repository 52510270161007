import * as Ariakit from '@ariakit/react'
import React from 'react'

export function Tabs({ children }: React.PropsWithChildren<Ariakit.TabProviderProps>) {
  return (
    <Ariakit.TabProvider>
      <div className="w-full space-y-1">{children}</div>
    </Ariakit.TabProvider>
  )
}
