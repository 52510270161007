import React from 'react'

import { Button, Icon, Paragraph } from '../../../main/components'
import { Popover, PopoverTrigger, usePopoverState } from '../../../main/components/popover'
import { HPSignalInterval, HP_SIGNAL_LABELS } from './types'

const IS_REDESIGN = FinvizSettings.hasRedesignEnabled

export default function FreeSwitch() {
  const state = usePopoverState({ placement: 'bottom-end' })
  const isOpen = state.useState('open')

  return (
    <>
      {IS_REDESIGN ? (
        <Button
          as={PopoverTrigger}
          state={state}
          theme="link"
          rightContent="chevronDown"
          title="Signals interval"
          size="xsmall"
          active={isOpen}
          className="ml-1"
        >
          {HP_SIGNAL_LABELS[HPSignalInterval.daily]}
        </Button>
      ) : (
        <Button
          as={PopoverTrigger}
          state={state}
          title="Signals interval"
          theme="link"
          size="none"
          className="tab-link ml-1 border-none hover:underline"
          style={{ fontSize: 11 }}
          contentClass="flex items-center"
        >
          {HP_SIGNAL_LABELS[HPSignalInterval.daily]}
          <Icon name="chevronDown" className="mx-px" width={IS_REDESIGN ? 16 : 14} />
        </Button>
      )}
      <Popover
        state={state}
        aria-label="Switch interval"
        className="w-40 p-1 font-sans"
        rounding={IS_REDESIGN ? 'regular' : 'none'}
        gutter={IS_REDESIGN ? 0 : 1}
      >
        <div className="flex flex-col items-center space-y-2 p-2">
          <Paragraph size="small" className="leading-snug font-medium">
            Get access to intraday data with Finviz*Elite.
          </Paragraph>
          <Button
            as="a"
            href="/elite.ashx?utm_source=finviz&utm_medium=banner&utm_campaign=intraday-signals"
            theme="violet"
            leftContent="lockOpened"
            size="small"
          >
            Subscribe
          </Button>
        </div>
      </Popover>
    </>
  )
}
