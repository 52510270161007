import classnames from 'classnames'
import { HTMLProps, PropsWithChildren, memo, useCallback, useLayoutEffect, useRef, useState } from 'react'

export interface Interaction {
  left: number
  top: number
}

/**
 * Clamp value between two bounds
 */
function clamp(num: number, min = 0, max = 1) {
  return num > max ? max : num < min ? min : num
}

/**
 * Check if an event was triggered by touch
 */
function isTouch(event: MouseEvent | TouchEvent): event is TouchEvent {
  return 'touches' in event
}

/**
 * Returns a relative position of the pointer inside the node's bounding box
 */
function getRelativePosition(node: HTMLDivElement, event: MouseEvent | TouchEvent): Interaction {
  const rect = node.getBoundingClientRect()

  // Get user's pointer position from `touches` array if it's a `TouchEvent`
  const pointer = isTouch(event) ? event.touches[0] : (event as MouseEvent)

  return {
    left: clamp((pointer.pageX - (rect.left + window.pageXOffset)) / rect.width),
    top: clamp((pointer.pageY - (rect.top + window.pageYOffset)) / rect.height),
  }
}

/**
 * Browsers introduced an intervention, making touch events passive by default.
 * This workaround removes `preventDefault` call from the touch handlers.
 * @see https://github.com/facebook/react/issues/19651
 */
function preventDefaultMove(event: MouseEvent | TouchEvent) {
  !isTouch(event) && event.preventDefault()
}

interface Props {
  onMove: (interaction: Interaction) => void
  onKey: (offset: Interaction) => void
}

function DraggablePickerComponent({
  onMove,
  onKey,
  className,
  ...props
}: PropsWithChildren<Props> & HTMLProps<HTMLDivElement>) {
  const container = useRef<HTMLDivElement>(null)
  const hasTouched = useRef(false)
  const [isDragging, setDragging] = useState(false)

  /**
   * Prevent mobile browsers from handling mouse events (conflicting with touch ones).
   * If we detected a touch interaction before, we prefer reacting to touch events only.
   */
  const isValid = useRef((event: MouseEvent | TouchEvent): boolean => {
    if (hasTouched.current && !isTouch(event)) return false
    if (!hasTouched.current) hasTouched.current = isTouch(event)
    return true
  })

  const handleMove = useCallback(
    (event: MouseEvent | TouchEvent) => {
      preventDefaultMove(event)

      // If user moves the pointer outside of the window or iframe bounds and release it there,
      // `mouseup`/`touchend` won't be fired. In order to stop the picker from following the cursor
      // after the user has moved the mouse/finger back to the document, we check `event.buttons`
      // and `event.touches`. It allows us to detect that the user is just moving his pointer
      // without pressing it down
      const isDown = isTouch(event) ? event.touches.length > 0 : event.buttons > 0

      if (isDown && container.current) {
        onMove(getRelativePosition(container.current, event))
      } else {
        setDragging(false)
      }
    },
    [onMove]
  )

  const handleMoveStart = useCallback(
    ({ nativeEvent }: React.MouseEvent | React.TouchEvent) => {
      preventDefaultMove(nativeEvent)

      if (!isValid.current(nativeEvent)) return

      // The node/ref must actually exist when user start an interaction.
      // We won't suppress the ESLint warning though, as it should probably be something to be aware of.
      onMove(getRelativePosition(container.current!, nativeEvent))
      setDragging(true)
    },
    [onMove]
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const keyCode = event.which || event.keyCode

      // Ignore all keys except arrow ones
      if (keyCode < 37 || keyCode > 40) return
      // Do not scroll page by arrow keys when document is focused on the element
      event.preventDefault()
      // Send relative offset to the parent component.
      // We use codes (37←, 38↑, 39→, 40↓) instead of keys ('ArrowRight', 'ArrowDown', etc)
      // to reduce the size of the library
      onKey({
        left: keyCode === 39 ? 0.05 : keyCode === 37 ? -0.05 : 0,
        top: keyCode === 40 ? 0.05 : keyCode === 38 ? -0.05 : 0,
      })
    },
    [onKey]
  )

  const handleMoveEnd = useCallback(() => setDragging(false), [])

  const toggleDocumentEvents = useCallback(
    (state: boolean) => {
      // add or remove additional pointer event listeners
      const toggleEvent = state ? window.addEventListener : window.removeEventListener
      toggleEvent(hasTouched.current ? 'touchmove' : 'mousemove', handleMove)
      toggleEvent(hasTouched.current ? 'touchend' : 'mouseup', handleMoveEnd)
    },
    [handleMove, handleMoveEnd]
  )

  useLayoutEffect(() => {
    toggleDocumentEvents(isDragging)
    return () => {
      isDragging && toggleDocumentEvents(false)
    }
  }, [isDragging, toggleDocumentEvents])

  return (
    <div
      {...props}
      ref={container}
      className={classnames(className, 'relative outline-none')}
      onTouchStart={handleMoveStart}
      onMouseDown={handleMoveStart}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    />
  )
}

export const DraggablePicker = memo(DraggablePickerComponent)
