import { setCookieRequest } from '../../main/services/api'
import { decodeQueryString, encodeQueryString } from '../queryString'
import { getCookie, setCookie } from './cookie'

export const formatDateToStringUS = (date: Date) =>
  date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })

const DRAWING_QUOTE_PAGE_COOKIE_NAME = 'charts-draw'
export function getIsDrawingEnabledOnQuotePage() {
  return getCookie(DRAWING_QUOTE_PAGE_COOKIE_NAME) === 'on'
}

export async function setIsDrawingEnabledOnQuotePage(isEnabled: boolean) {
  return setCookieRequest(DRAWING_QUOTE_PAGE_COOKIE_NAME, isEnabled ? 'on' : 'off')
    .catch(() => {
      // fallback to JS otherwise draw toggle won't work if API doesn't work
      setIsDrawingEnabledOnQuotePageCookieViaJS(isEnabled)
    })
    .finally(() => {
      const { i: idea, ...rest } = decodeQueryString()
      if (idea) {
        document.location = `quote.ashx?${encodeQueryString(rest)}`
      } else {
        document.location.reload()
      }
    })
}

function setIsDrawingEnabledOnQuotePageCookieViaJS(isEnabled: boolean) {
  const expires = new Date()
  expires.setMonth(expires.getMonth() + 1)
  setCookie(DRAWING_QUOTE_PAGE_COOKIE_NAME, isEnabled ? 'on' : 'off', expires)
}

export function parseInitData<T = unknown>(elementId: string): T | undefined {
  try {
    const initDataElement = document.getElementById(elementId)!
    return JSON.parse(initDataElement.textContent!)
  } catch (e) {
    Sentry.captureException(e)
    return
  }
}

export function getSidebarFromCookie() {
  const chartsUrl = getCookie('chartsUrl')
  const decodedCookie = chartsUrl ? decodeQueryString<{ sidebar?: string }>(chartsUrl) : null
  return decodedCookie?.sidebar || undefined
}
