interface PointerProps {
  className?: string
  top?: number
  left: number
  color: string
}

export function Pointer({ color, left, top = 0.5 }: PointerProps) {
  return (
    <div
      className="absolute -m-3 h-6 w-6 rounded-full bg-white bg-checkerboard bg-fixed shadow sm:-m-2 sm:h-4 sm:w-4"
      style={{
        top: `${top * 100}%`,
        left: `${left * 100}%`,
      }}
    >
      <div className="h-full w-full rounded-full border-2 border-white" style={{ backgroundColor: color }} />
    </div>
  )
}
