import { ButtonRoundingType, ButtonThemeType } from '../button'
import { IconNameType } from '../icon'

export enum ToolbarTheme {
  chipTransparent, // (default)
  alternative,
}

export enum ToolbarDirection {
  vertical,
  horizontal,
}

export type ToolbarDirectionType = keyof typeof ToolbarDirection

export interface IButtonThemeSettings {
  className?: string
  theme: ButtonThemeType
  rounding: ButtonRoundingType
}

export interface IToolbarItem {
  id: string
  icon: IconNameType
  iconActive?: IconNameType
  iconAction?: IconNameType
  title: string
  titleActive?: string
  titleAction?: string
}

export type ToolbarThemeType = keyof typeof ToolbarTheme
