import * as Ariakit from '@ariakit/react'
import classnames from 'classnames'
import * as React from 'react'

import { isMobile } from '../../../app/shared/isMobile'
import { DropdownItem } from '../dropdown'
import { Icon } from '../icon'
import { NativeSelect } from '../select/NativeSelect'
import { useToolbarContext } from './hooks'
import { IToolbarItem, ToolbarTheme } from './interfaces'
import { ToolbarButton } from './toolbar-button'

const isMobileBrowser = isMobile()

export interface ToolbarGroupProps {
  /**
   * Main button item
   */
  defaultItem: IToolbarItem

  /**
   * Shows spinner instead of icon
   */
  isLoading?: boolean

  /**
   * Current selected item id
   */
  isActive: boolean

  /**
   * Should indicate some action in progress
   */
  isInAction?: boolean

  /**
   * Array of items
   */
  groupItems: IToolbarItem[]

  /**
   * Expand group button label
   */
  groupTitle?: string

  /**
   * On item select callback
   */
  onChange: (id: string, trigger: 'button' | 'select' | 'group') => void

  /**
   * Use native select for mobile devices
   *
   * @default true
   */
  useNativeSelect?: boolean

  /**
   * Is item disabled
   */
  disabled?: boolean

  /**
   * Tooltip message if item is disabled
   */
  disabledTooltip?: string

  rowId?: number
}

export const ToolbarGroup = React.forwardRef(function ToolbarGroup(
  {
    defaultItem,
    isActive,
    isLoading,
    groupItems,
    groupTitle,
    onChange,
    useNativeSelect = isMobileBrowser,
    disabled,
    disabledTooltip,
    rowId,
  }: ToolbarGroupProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { theme, isStretched } = useToolbarContext()
  const [selectedItem, setSelectedItem] = React.useState(defaultItem)
  const groupId = groupTitle?.replace(/\s/g, '-').toLocaleLowerCase()
  const isAlternativeTheme = theme === ToolbarTheme.alternative

  // Setup states, pass Menubar context to this menu so we can control the shared menu
  const context = Ariakit.useMenuContext()
  const menu = Ariakit.useMenuStore({ store: context })

  // We have multiple menu triggers but just one shared menu so we need a way to know which button has been hovered
  const [menuButton, setMenuButton] = React.useState<HTMLDivElement | null>(null)
  const open = menu.useState((state) => state.mounted && state.anchorElement === menuButton)

  // Find the element to render the menu into using Portal
  const parentMenu = menu.useState('contentElement')
  const menuContentElement = React.useMemo(() => parentMenu?.querySelector('div'), [parentMenu])

  /**
   * Charts prevent bubling mouseover so we need to hide the menu manually
   */
  const hideMenu = menu.hide
  React.useEffect(() => {
    const onMouseLeave = () => hideMenu()

    parentMenu?.addEventListener('mouseleave', onMouseLeave)
    return () => parentMenu?.removeEventListener('mouseleave', onMouseLeave)
  }, [hideMenu, parentMenu])

  const showMenu = menu.show
  const handleMenuOpen = React.useCallback(
    (ev: React.SyntheticEvent<HTMLElement, Event>) => {
      menu.setDisclosureElement(ev.currentTarget)
      menu.setAnchorElement(ev.currentTarget)
      showMenu()
    },
    [menu, showMenu]
  )

  const trigger = (
    <ToolbarButton
      ref={ref}
      rowId={rowId}
      className="relative"
      data-testid={`toolbar-group-${groupId}-expand`}
      item={selectedItem}
      active={isActive}
      disabled={disabled}
      title={groupTitle}
      disabledTooltip={disabledTooltip}
      isLoading={isLoading}
    >
      <Icon name={(isActive && selectedItem.iconActive) || selectedItem.icon} width={32} className="shrink-0" />
      <Icon name="toolExpand" width={32} className="absolute flex" />
    </ToolbarButton>
  )

  if (useNativeSelect) {
    return (
      <label
        className={classnames('relative flex', {
          grow: isAlternativeTheme && isStretched,
        })}
      >
        {trigger}
        <NativeSelect
          className="pointer-events-none"
          items={groupItems.map((item) => ({ value: item.id, label: item.title }))}
          onChange={(option) => {
            setSelectedItem(groupItems![groupItems!.findIndex((item) => item.id === option.value)])
            onChange(option.value, 'select')
          }}
          onClick={() => onChange(selectedItem.id, 'select')}
        />
      </label>
    )
  }

  return (
    <Ariakit.MenuProvider store={menu} parent={null} focusLoop virtualFocus>
      <Ariakit.MenuButton
        ref={setMenuButton}
        showOnHover
        render={trigger}
        onFocusVisible={handleMenuOpen}
        onClick={(ev: React.MouseEvent<HTMLButtonElement>) => {
          // Preventing this means the dropdown will only open on hover not by a click
          ev.preventDefault()
        }}
        onPointerDown={(ev: React.PointerEvent<HTMLButtonElement>) => {
          // Finally, pre-select the current group tool
          onChange(selectedItem.id, 'group')
          handleMenuOpen(ev)
        }}
      />
      {open && (
        // Render this menu's contents into the parent menu.
        <Ariakit.Portal portalElement={menuContentElement} render={<React.Fragment />}>
          {groupItems.map((item) => (
            <DropdownItem
              key={item.id}
              store={menu}
              leftContent={<Icon name={item.icon} className="mr-1.5 shrink-0" width={28} />}
              data-testid={`toolbar-group-${groupId}-${item.id}`}
              rounding={theme === ToolbarTheme.alternative && !FinvizSettings.hasRedesignEnabled ? 'none' : undefined}
              onPointerDown={() => {
                setSelectedItem(item)
                onChange(item.id, 'select')
              }}
            >
              {item.title}
            </DropdownItem>
          ))}
        </Ariakit.Portal>
      )}
    </Ariakit.MenuProvider>
  )
})
