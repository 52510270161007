import * as Ariakit from '@ariakit/react'
import * as React from 'react'

import { OptionButton, OptionButtonProps } from '../OptionButton'
import { BUTTON_DEFAULT_ELEMENT } from '../button'
import { PropsWithAs } from '../types'

interface DropdownItemProps extends OptionButtonProps {
  store?: Ariakit.MenuStore

  subtitle?: React.ReactNode

  /**
   * Whether clicking the item should also close the dropdown. Only applies to
   * items without a submenu
   *
   * @default true
   */
  hideOnClick?: boolean
}

function DropdownItemComponent<TagType extends React.ElementType = typeof BUTTON_DEFAULT_ELEMENT>(
  { as: asComponent, ...props }: PropsWithAs<TagType> & DropdownItemProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return <Ariakit.MenuItem ref={ref} {...props} render={<OptionButton as={asComponent} />} />
}

/**
 * We need to forward ref to the function, but typescript looses the type of the
 * props param so we need to typecast to get suggestions to work
 */
export const DropdownItem = React.forwardRef(DropdownItemComponent) as <
  TagType extends React.ElementType = typeof BUTTON_DEFAULT_ELEMENT,
>(
  props: PropsWithAs<TagType> & DropdownItemProps & { ref?: React.ForwardedRef<HTMLElement> }
) => ReturnType<typeof DropdownItemComponent>
