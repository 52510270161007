import classNames from 'classnames'

import { Icon, Select, SelectOption } from '../../../main/components'
import { HPSignalInterval, HP_SIGNAL_LABELS } from './types'

const IS_REDESIGN = FinvizSettings.hasRedesignEnabled
const OPTIONS = Object.entries(HP_SIGNAL_LABELS).map(([value, label]) => ({ value: value as HPSignalInterval, label }))
const ROUNDING = IS_REDESIGN ? undefined : 'none'

type EliteSwitchProps = {
  isLoading: boolean
  signal: HPSignalInterval
  onSignalChange: (signals: HPSignalInterval) => void
}

export default function EliteSwitch({ isLoading, signal, onSignalChange }: EliteSwitchProps) {
  return (
    <Select
      aria-label="Signals interval"
      theme={IS_REDESIGN ? 'link' : 'transparent'}
      size={IS_REDESIGN ? 'xsmall' : 'xxsmall'}
      rounding={ROUNDING}
      className={classNames({ 'text-link hover:underline': !IS_REDESIGN, 'ml-1': IS_REDESIGN, 'ml-0.5': !IS_REDESIGN })}
      value={OPTIONS.find((option) => option.value === signal)}
      rightContent={<Icon name="chevronDown" width={IS_REDESIGN ? 16 : 14} />}
      onChange={({ value }) => {
        onSignalChange(value)
      }}
      placement="bottom-end"
      orientation="horizontal"
      isLoading={isLoading}
      disabled={isLoading}
      gutter={IS_REDESIGN ? 0 : 1}
      // override height defined by button to not stretch old hp signal tables
      style={IS_REDESIGN ? undefined : { height: 'auto' }}
    >
      {OPTIONS.map((item) => (
        <SelectOption
          size="small"
          key={item.value}
          rounding={ROUNDING}
          activeTheme="chipTransparent"
          active={signal === item.value || undefined}
          value={item}
        >
          {item.label}
        </SelectOption>
      ))}
    </Select>
  )
}
