/**
 * Keep this file in sync with `app/utils/colors.ts` in charts repo
 * */

export interface RGBA {
  r: number
  g: number
  b: number
  a: number
}

export interface HSVA {
  h: number
  s: number
  v: number
  a: number
}

export interface HSLA {
  h: number
  s: number
  l: number
  a: number
}

/**
 * Convert Hex string to RGBA object.
 * @see https://css-tricks.com/converting-color-spaces-in-javascript/
 *
 * Input: color in hex3, hex4, hex6 or hex8 format (#fff)
 *
 * Output: { r: [0,255], g: [0,255], b: [0,255], a: [0,1]}
 */
export function hexStringToRGBA(hexString: string): RGBA {
  const hexColor = removeHashSymbol(hexString)
  let r: string | number = 0
  let g: string | number = 0
  let b: string | number = 0
  let a: string | number = 255

  // 3 digits
  if (hexColor.length === 3 || hexColor.length === 4) {
    r = '0x' + hexColor[0] + hexColor[0]
    g = '0x' + hexColor[1] + hexColor[1]
    b = '0x' + hexColor[2] + hexColor[2]
    // Get channel if defined
    a = hexColor.length === 4 ? '0x' + hexColor[3] + hexColor[3] : a

    // 6 digits
  } else if (hexColor.length === 6 || hexColor.length === 8) {
    r = '0x' + hexColor[0] + hexColor[1]
    g = '0x' + hexColor[2] + hexColor[3]
    b = '0x' + hexColor[4] + hexColor[5]
    // Get channel if defined
    a = hexColor.length === 8 ? '0x' + hexColor[6] + hexColor[7] : a
  }

  return { r: Number(r), g: Number(g), b: Number(b), a: Math.min(Math.round((Number(a) / 255) * 100) / 100, 1) }
}

/**
 * Convert HEX string to HSVA color representation.
 *
 * Input: color in hex3, hex4, hex6 or hex8 format (#fff)
 *
 * Output: { h: [0,360], s: [0,1], v: [0,1], a: [0,1]}
 */
export function hexStringToHSVA(hexString: string) {
  return rgbaToHSVA(hexStringToRGBA(hexString))
}

/**
 * Convert HEX string to HSLA color representation.
 *
 * Input: color in hex3, hex4, hex6 or hex8 format (#fff)
 *
 * Output: { h: [0,360], s: [0,1], l: [0,1], a: [0,1]}
 */
export function hexStringToHSLA(hexString: string) {
  return rgbaToHSLA(hexStringToRGBA(hexString))
}

/**
 * Convert alpha value to hex value
 *
 * Input: [0,1]
 *
 * Output: alpha in HEX format
 */
export function alphaToHex(alpha: number) {
  return Math.max(Math.min(Math.round(alpha * 255), 255), 0)
    .toString(16)
    .padStart(2, '0')
}

/**
 * Convert RGBA color to HEX string.
 *
 * Input: { r: [0,255], g: [0,255], b: [0,255], a: [0,1]}
 *
 * Output: color in hex6 or hex8 depending on whether or not alpha is enabled
 */
export function rgbaToHexString(color: RGBA, alpha = false) {
  const r = color.r.toString(16)
  const g = color.g.toString(16)
  const b = color.b.toString(16)
  const hexString = '#' + r.padStart(2, '0') + g.padStart(2, '0') + b.padStart(2, '0')

  if (!alpha || color.a === 1) {
    return hexString
  }

  return `${hexString}${alphaToHex(color.a)}`
}

/**
 * Convert HSVA object to HEX string
 *
 * @see https://css-tricks.com/converting-color-spaces-in-javascript/
 *
 * Input: { h: [0,360], s: [0,1], v: [0,1], a: [0,1]}
 *
 * Output: { r: [0,255], g: [0,255], b: [0,255], a: [0,1]}
 */
export function hsvaToHEXString(color: HSVA, alpha = false) {
  const rgbaColor = hsvaToRGBA(color)

  return rgbaToHexString(rgbaColor, alpha)
}

/**
 * Convert HSLA object to HEX string
 *
 * @see https://css-tricks.com/converting-color-spaces-in-javascript/
 *
 * Input: { h: [0,360], s: [0,1], l: [0,1], a: [0,1]}
 *
 * Output: { r: [0,255], g: [0,255], b: [0,255], a: [0,1]}
 */
export function hslaToHexString(color: HSLA, alpha = false) {
  const rgbaColor = hslaToRGBA(color)

  return rgbaToHexString(rgbaColor, alpha)
}

/**
 * Convert RGBA color to HSVA
 * @see https://stackoverflow.com/a/54070620/6662683
 *
 * Input: { r: [0,255], g: [0,255], b: [0,255], a: [0,1]}
 *
 * Output: { h: [0,360], s: [0,1], v: [0,1], a: [0,1]}
 */
export function rgbaToHSVA(color: RGBA): HSVA {
  const r = color.r / 255
  const g = color.g / 255
  const b = color.b / 255

  const v = Math.max(r, g, b)
  const c = v - Math.min(r, g, b)
  const h = c && (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c)

  return {
    h: 60 * (h < 0 ? h + 6 : h),
    s: v ? c / v : 0,
    v: v,
    a: color.a,
  }
}

/**
 * Convert RGBA to HSLA
 * @see https://stackoverflow.com/a/54071699/6662683
 *
 * Input: { r: [0,255], g: [0,255], b: [0,255], a: [0,1]}
 *
 * Output: { h: [0,360], s: [0,1], l: [0,1], a: [0,1]}
 */
export function rgbaToHSLA(color: RGBA): HSLA {
  const r = color.r / 255
  const g = color.g / 255
  const b = color.b / 255

  const v = Math.max(r, g, b)
  const c = v - Math.min(r, g, b)
  const f = 1 - Math.abs(v + v - c - 1)
  const h = c && (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c)

  return {
    h: 60 * (h < 0 ? h + 6 : h),
    s: f ? c / f : 0,
    l: (v + v - c) / 2,
    a: color.a,
  }
}

/**
 * Convert HSVA color to RGBA
 * @see https://stackoverflow.com/a/54024653/6662683
 *
 * Input: { h: [0,360], s: [0,1], v: [0,1], a: [0,1]}
 *
 * Output: { r: [0,255], g: [0,255], b: [0,255], a: [0,1]}
 */
export function hsvaToRGBA(color: HSVA): RGBA {
  const f = (n: number, k = (n + color.h / 60) % 6) => color.v - color.v * color.s * Math.max(Math.min(k, 4 - k, 1), 0)

  return {
    r: Math.round(f(5) * 255),
    g: Math.round(f(3) * 255),
    b: Math.round(f(1) * 255),
    a: Math.round(color.a * 100) / 100,
  }
}

/**
 * Convert HSLA color to RGBA
 * @see https://stackoverflow.com/a/54014428/6662683
 *
 * Input: { h: [0,360], s: [0,1], l: [0,1], a: [0,1]}
 *
 * Output: { r: [0,255], g: [0,255], b: [0,255], a: [0,1]}
 */
export function hslaToRGBA(color: HSLA): RGBA {
  const a = color.s * Math.min(color.l, 1 - color.l)
  const f = (n: number, k = (n + color.h / 30) % 12) => color.l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)

  return {
    r: Math.round(f(0) * 255),
    g: Math.round(f(8) * 255),
    b: Math.round(f(4) * 255),
    a: color.a,
  }
}

/**
 * Convert RGBA color to rgba() string
 *
 * Input: { r: [0,255], g: [0,255], b: [0,255], a: [0,1]}
 *
 * Output: rgba(r, g, b, a)
 */
export function rgbaToRGBAString(color: RGBA) {
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
}

/**
 * Convert rgba() string to RGBA color
 *
 * Input: rgba(r, g, b, a)
 *
 * Output: { r: [0,255], g: [0,255], b: [0,255], a: [0,1]}
 */
export function rgbaStringToObject(color: string) {
  const colorParts = /^rgba?\(([0-9]{1,3})[ ,]+([0-9]{1,3})[ ,]+([0-9]{1,3})[ ,]*([01].?[0-9]*)?\)$/.exec(color)
  const [red, green, blue, alpha = '1'] = colorParts?.slice(1) || [0, 0, 0, 1].map(String)
  return {
    r: Number.parseInt(red),
    g: Number.parseInt(green),
    b: Number.parseInt(blue),
    a: Number.parseFloat(alpha),
  }
}

/**
 * Convert HSLA color to hsla() string
 *
 * Input: { h: [0,360], s: [0,1], l: [0,1], a: [0,1]}
 *
 * Output: hsla(h, s, l, a)
 */
export function hslaToHSLAString(color: HSLA) {
  return `hsla(${Math.round(color.h)}, ${Math.round(color.s * 10000) / 100}%, ${Math.round(color.l * 10000) / 100}%, ${
    color.a
  })`
}

export function removeHashSymbol(strColor: string) {
  return strColor.replace(/#/g, '')
}

/**
 * Check if the param is a valid color by setting it as style
 */
export function isValidColor(strColor: string) {
  const isRgb = strColor.startsWith('rgba')
  const s = new Option().style

  s.color = isRgb ? strColor : `#${removeHashSymbol(strColor)}`

  return s.color !== ''
}

/**
 * Normalize color to be a hex value
 */
export function convertColorToHEX(strColor: string) {
  const isRgb = getIsRgb(strColor)

  if (isRgb) {
    return rgbaToHexString(rgbaStringToObject(strColor), true)
  }

  return strColor
}

/**
 * Returns true if color is rgb or rgba
 */
export function getIsRgb(strColor: string, isAlphaAllowed = true) {
  const rgbKeys = ['rgb']
  if (isAlphaAllowed) {
    rgbKeys.push('rgba')
  }
  return rgbKeys.some((startsWith) => strColor.startsWith(startsWith))
}

/**
 * Convert any color format to HSVA (internal color picker representation)
 */
export function getHSVAFromColor(strColor: string) {
  if (getIsRgb(strColor)) {
    return rgbaToHSVA(rgbaStringToObject(strColor))
  }

  return hexStringToHSVA(strColor)
}

/**
 * Stringify HSVA color representation into a output format
 */
export function stringifyHSVAColor(color: HSVA, output: 'rgba' | 'hex', canSelectAlpha?: boolean) {
  switch (output) {
    case 'rgba':
      return rgbaToRGBAString(hsvaToRGBA(color))
    default:
      return hsvaToHEXString(color, canSelectAlpha)
  }
}

/**
 * Get luma value from RGBA color using the Rec. 709 coefficients
 * @see https://en.wikipedia.org/wiki/Luma_%28video%29
 *
 * If luma is >= 165 the foreground color should be dark
 */
export function getLumaFromRGBA(color: RGBA) {
  return 0.2126 * color.r + 0.7152 * color.g + 0.0722 * color.b
}

/**
 * Checks if luma is >= 165 or alpha < 0.3
 */
export function getIsColorTooLightOrTransparent(color: RGBA) {
  return getLumaFromRGBA(color) >= 165 || color.a < 0.5
}

/**
 * Set specific alpha for a hex color
 */
export function getHEXWithSpecificAplha(color: string, alpha = 1) {
  const rgbaColor = hexStringToRGBA(color)
  return rgbaToHexString({ ...rgbaColor, a: alpha }, true)
}

/**
 * Get rgba from valid color string
 */
export function getRGBAValueFromValidColorString(value: string) {
  if (getIsRgb(value)) return rgbaToRGBAString(rgbaStringToObject(value))
  return rgbaToRGBAString(hexStringToRGBA(value))
}
