import classnames from 'classnames'
import React from 'react'

import { Icon, IconNameType, isValidIconName } from '../icon'
import { getIsColorTooLightOrTransparent, hexStringToRGBA } from './util'

interface ColorPreviewProps {
  /**
   * Name of an icon or JSX.Element
   */
  icon?: JSX.Element | IconNameType

  /**
   * One or more colors to show in the preview
   */
  color: string | string[]

  /**
   * Wrapper classname
   */
  className?: string
}

export function ColorPreview({ icon, color, className }: ColorPreviewProps) {
  const colors = Array.isArray(color) ? color : [color]
  const isTooLight = getIsColorTooLightOrTransparent(hexStringToRGBA(colors[0]))
  return (
    <div
      className={classnames(
        'relative flex h-full w-full overflow-hidden border border-gray-100 bg-white bg-checkerboard dark:border-gray-700',
        className
      )}
    >
      {icon && (
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center p-0.5">
          {isValidIconName(icon) ? (
            <Icon name={icon} className="shrink-0" color={isTooLight ? 'black' : 'white'} width="100%" height="100%" />
          ) : (
            icon
          )}
        </div>
      )}
      {colors.map((color, index) => (
        // &nbsp has to be there because of a bug on safari which ignores h-full when empty element
        <div key={index} className="h-full flex-1" style={{ backgroundColor: color }}>
          &nbsp;
        </div>
      ))}
    </div>
  )
}
