import classnames from 'classnames'
import React from 'react'

import { isMobile } from '../../../app/shared/isMobile'
import { Button, ButtonComponentProps, ButtonRounding, ButtonSizeType } from '../button'
import { ButtonGroup } from '../button-group'
import { IconNameType } from '../icon'
import { Popover, PopoverTrigger, usePopoverState } from '../popover'
import { ColorPreview } from './color-preview'
import { ColorPicker, ColorPickerProps } from './picker'
import { PickerInput } from './picker-input'
import * as colorUtil from './util'

interface MultiColorPickerProps extends ColorPickerProps {
  /**
   * Label for color text input
   *
   * @default undefined
   */
  colorInputLabel?: string
}

export function MultiColorPicker({
  colorInputLabel,
  onChange,
  canSelectCustom = true,
  ...props
}: MultiColorPickerProps) {
  const isMobileDevice = isMobile()

  return (
    <>
      {canSelectCustom && !isMobileDevice && (
        <div className="p-2 pb-0">
          <PickerInput
            data-testid="color-picker-input"
            label={colorInputLabel}
            color={props.color}
            hasColorPreview={false}
            onChange={onChange}
          />
        </div>
      )}
      <div
        className={classnames(
          'w-full min-w-64 p-2',
          canSelectCustom && {
            'h-72': isMobileDevice,
            'h-64': !isMobileDevice,
          }
        )}
      >
        <ColorPicker {...props} canSelectCustom={canSelectCustom} onChange={onChange} onPaletteItemClick={() => {}} />
      </div>
    </>
  )
}

interface ColorTabButtonProps extends ButtonComponentProps {
  /*
   * Preview color
   * */
  color: string
}

export function ColorTabButton({ color, children, ...props }: ColorTabButtonProps) {
  return (
    <Button
      leftContent={<ColorPreview color={color} className={classnames('h-4 w-4', ButtonRounding.small)} />}
      {...props}
    >
      {children}
    </Button>
  )
}

type ButtonColorProperties = { color: string; label: string }

interface ButtonColorPickerProps<ColorType extends ButtonColorProperties>
  extends Omit<ColorPickerProps, 'color' | 'onChange'> {
  /**
   * Temporary prop to enable/disable multi picker funcionality,
   * in the future we'll only use secondaryColor prop to decide if the picker is multi picker or not
   *
   * @default undefined
   */
  isMultiPicker?: boolean

  /**
   * Button classname
   */
  className?: string

  /**
   * Array of objects with color and value
   */
  colors: ColorType[]

  /**
   * Color onchange callback
   */
  onChange: (colors: ColorType[], originalColor: colorUtil.HSVA) => void

  /**
   * Button size
   *
   * @default undefined
   */
  size?: ButtonSizeType
  /**
   * Label for color text input
   *
   * @default undefined
   */
  colorInputLabel?: string

  /**
   * Name of an icon or JSX.Element
   */
  icon?: JSX.Element | IconNameType

  /**
   * Identifier for e2e testing
   *
   * @default undefined
   */
  buttonPickerDataTestId?: string
}

export function ButtonColorPicker<ColorType extends ButtonColorProperties>({
  size,
  icon,
  colors,
  className,
  onChange,
  buttonPickerDataTestId,
  ...props
}: ButtonColorPickerProps<ColorType>) {
  const popoverState = usePopoverState()
  const isOpen = popoverState.useState('open')
  const isMultiPicker = !!props.isMultiPicker && colors.length > 1
  const [activeColorIndex, setActiveColorIndex] = React.useState(0)
  const handleColorChange = React.useCallback(
    (color: string, originalColor: colorUtil.HSVA) => {
      onChange(
        colors.map((colorObject, index) => (index === activeColorIndex ? { ...colorObject, color } : colorObject)),
        originalColor
      )
    },
    [activeColorIndex, colors, onChange]
  )
  return (
    <>
      <Button
        state={popoverState}
        as={PopoverTrigger}
        data-testid={buttonPickerDataTestId}
        theme="outline"
        className={classnames('p-0.5', className)}
        rounding="regular"
        appearance="square"
        size={size}
        active={isOpen}
      >
        <ColorPreview
          icon={icon}
          color={colors.map(({ color }) => color)}
          className={classnames('h-full w-full', ButtonRounding.small)}
        />
      </Button>
      <Popover state={popoverState} aria-label="Color picker">
        {isMultiPicker && (
          <div className="flex justify-center p-2 pb-0">
            <ButtonGroup childComponent={ColorTabButton} hasDivider={false}>
              {colors.map(({ color, label }, index) => (
                <ColorTabButton
                  key={index}
                  color={color}
                  active={activeColorIndex === index}
                  onClick={() => setActiveColorIndex(index)}
                >
                  {label}
                </ColorTabButton>
              ))}
            </ButtonGroup>
          </div>
        )}
        <MultiColorPicker
          key={activeColorIndex}
          {...props}
          color={colors[activeColorIndex].color}
          onChange={handleColorChange}
        />
      </Popover>
    </>
  )
}
