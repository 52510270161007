import * as React from 'react'

import { NotificationContextType, useNotification } from './hooks'

export function withNotificationContext<T extends { notificationContext: NotificationContextType }>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName = WrappedComponent.displayName ?? WrappedComponent.name ?? 'Component'
  const WithNotificationContext = (props: Omit<T, 'notificationContext'>) => {
    const notification = useNotification()
    return <WrappedComponent {...(props as T)} notificationContext={notification} />
  }

  WithNotificationContext.displayName = `withNotificationContext(${displayName})`

  return WithNotificationContext
}
