import React from 'react'
import * as ReactDOM from 'react-dom'

import { setCookieRequest } from '../../../main/services/api'
import { getCookie } from '../../shared/cookie'
import { getRefreshData, replaceSignalTables } from '../refresh'
import EliteSwitch from './EliteSwitch'
import FreeSwitch from './FreeSwitch'
import { HPSignalInterval, HP_SIGNAL_COOKIE } from './types'

const HEADER_SWITCH_SELECTOR = '.js-signal-switch'
const SWITCH_BUTTON_SELECTOR = '.js-signal-switch-button'
const signalSwitchesElements = Array.from(document.querySelectorAll<HTMLElement>(HEADER_SWITCH_SELECTOR))

function getSignalsCookie() {
  const value = getCookie(HP_SIGNAL_COOKIE)
  let signal = HPSignalInterval.daily
  const castedValue = value as HPSignalInterval

  if (FinvizSettings.hasUserPremium && !!castedValue) signal = castedValue
  return signal
}

async function handleSignalIntervalChange() {
  const newDocument = await getRefreshData()
  if (newDocument) {
    replaceSignalTables(newDocument)
  } else {
    document.location.reload()
  }
}

export default function HomepageSignals() {
  const [signal, setSignal] = React.useState(getSignalsCookie())
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    // remove inlined buttons from html template
    document.querySelectorAll(SWITCH_BUTTON_SELECTOR).forEach((element) => {
      element.remove()
    })
  }, [])

  const handleSignalChange = async (changedSignal: HPSignalInterval) => {
    if (changedSignal !== signal) {
      setSignal(changedSignal)
      setIsLoading(true)
      try {
        await setCookieRequest(HP_SIGNAL_COOKIE, changedSignal)
        await handleSignalIntervalChange().catch()
      } catch {
        setSignal(signal)
      }
      setIsLoading(false)
    }
  }

  return (
    <>
      {signalSwitchesElements.map((element) =>
        ReactDOM.createPortal(
          FinvizSettings.hasUserPremium ? (
            <EliteSwitch isLoading={isLoading} signal={signal} onSignalChange={handleSignalChange} />
          ) : (
            <FreeSwitch />
          ),
          element
        )
      )}
    </>
  )
}
