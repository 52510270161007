import * as Ariakit from '@ariakit/react'
import classnames from 'classnames'
import * as React from 'react'

import { getVisualViewport, useVisualViewport } from '../../hooks/useVisualViewport'
import { NotificationContext, NotificationOptions, NotificationPosition } from './hooks'

interface NotificationState {
  element: JSX.Element
  options: Partial<NotificationOptions>
}

export function NotificationWrapper(props: React.PropsWithChildren<any>) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [notification, setNotification] = React.useState<NotificationState | null>(null)
  const { viewport, setViewport } = useVisualViewport({ enabled: !!notification })

  const context = React.useRef({
    show: (element: JSX.Element, options: Partial<NotificationOptions> = {}) => {
      setViewport(() => getVisualViewport(window.visualViewport))
      setNotification({ element, options })
      setIsOpen(true)
    },
    hide: () => setIsOpen(false),
  })

  const positionClass = React.useMemo(() => {
    const { position = NotificationPosition.BottomLeft } = notification?.options ?? {}
    return {
      '[--inset-t:1rem] items-start pt-inset-top': [
        NotificationPosition.TopLeft,
        NotificationPosition.TopRight,
      ].includes(position),
      '[--inset-r:1rem] justify-end landscape-secondary:pr-inset-right': [
        NotificationPosition.TopRight,
        NotificationPosition.BottomRight,
      ].includes(position),
      '[--inset-b:1rem] items-end pb-inset-bottom': [
        NotificationPosition.BottomLeft,
        NotificationPosition.BottomRight,
      ].includes(position),
      '[--inset-l:1rem] justify-start landscape-primary:pl-inset-left': [
        NotificationPosition.TopLeft,
        NotificationPosition.BottomLeft,
      ].includes(position),
    }
  }, [notification?.options])

  const WrapperComponent = React.useMemo(
    () => (notification?.options.inline ? React.Fragment : Ariakit.Portal),
    [notification?.options.inline]
  )

  return (
    <NotificationContext.Provider value={context.current}>
      {props.children}
      {isOpen && (
        <WrapperComponent>
          <Ariakit.Dialog
            role="alert"
            aria-label="Notification"
            className={classnames(
              positionClass,
              'pointer-events-none fixed left-0 top-0 z-notification flex origin-top-left p-2 opacity-0 transition-opacity data-[enter]:opacity-100 data-[leave]:transition-none sm:p-5'
            )}
            unmountOnHide
            // Manually control the state
            open={isOpen}
            onClose={context.current.hide}
            // Disable modal settings so that we can still interact with other elements
            modal={false}
            backdrop={false}
            // Disable focus on the dialog element itself
            focusable={false}
            // Disable interactions
            hideOnEscape={false}
            hideOnInteractOutside={false}
            autoFocusOnShow={false}
            autoFocusOnHide={false}
            // Set viewport styles so that notifications are visible on mobile zoom
            style={{
              width: viewport.width,
              height: viewport.height,
              transform: `translate(${viewport.left}px, ${viewport.top}px) scale(${viewport.scale})`,
              willChange: 'auto',
            }}
          >
            {notification?.element}
          </Ariakit.Dialog>
        </WrapperComponent>
      )}
    </NotificationContext.Provider>
  )
}
