import * as React from 'react'
import Shepherd from 'shepherd.js'

import { TourDefinition } from './types'
import * as tourUtils from './utils'

export const DEFAULT_OPTIONS = {
  exitOnEsc: true,
  useModalOverlay: true,
}

/**
 * Hook which controls shepherd.js. The library is lazy-loaded when the tour starts
 *
 * Returns:
 * - `isLoading`: state of the library loading
 * - `currentTour`: tour object, only defined after library has loaded
 * - `startTour`: load the library and start tour. Returns `currentTour`
 * - `getStepsForUser`: returns a list of tour steps based on tours the user finished
 * - `completeTour`: persists the last finished tour to local storage
 */
export function useTour(options?: Shepherd.Tour.TourOptions) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [currentTour, setTour] = React.useState<Shepherd.Tour | null>(null)

  /**
   * We’re loading shepherd asynchronously and want to have loading state so we use
   * AbortController to check if the component is still mounted.
   */
  const controller = React.useRef('AbortController' in window ? new AbortController() : undefined)
  const loadingSetter = React.useRef((state: boolean) => {
    if (controller.current?.signal.aborted) return

    setIsLoading(state)
  })
  // Cleanup effect to abort the controller
  React.useEffect(() => () => controller.current?.abort(), [])

  const startTour = React.useCallback(
    async (definition: TourDefinition, setComplete = true) => {
      const shepherdLibrary = await tourUtils.loadShepherdLibrary(loadingSetter.current)

      if (shepherdLibrary && !controller.current?.signal.aborted) {
        const tour = currentTour ?? new shepherdLibrary.Tour({ ...DEFAULT_OPTIONS, ...options })

        if (!currentTour) setTour(tour)

        // Reset the tour steps in case they were updated
        Array.from(tour.steps).forEach((step) => tour.removeStep(step.id))

        const processedSteps = tourUtils.processSteps(tour, definition.steps)
        tour.addSteps(processedSteps)

        if (setComplete) {
          tourUtils.completeTour(definition.id)
        }

        tour.start()
        window.gtag?.('event', 'tour-start', { event_label: definition.initialTour ?? definition.id })

        return tour
      }
    },
    [currentTour, options]
  )

  return {
    isLoading,
    currentTour,
    startTour,
    getStepsForUser: tourUtils.getStepsForUser,
    completeTour: tourUtils.completeTour,
  }
}
