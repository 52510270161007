import keyMirror from 'keymirror'

const ActionTypes = keyMirror({
  RENDER_TREEMAP: null,
  CHANGE_TRANSLATE: null,
  SET_HOVERED_NODE: null,

  LOAD_SPARKLINES_STARTED: null,
  LOAD_SPARKLINES_COMPLETED: null,
  LOAD_SPARKLINES_FAILED: null,
  RESET_SPARKLINE_DATA: null,

  INIT_STARTED: null,
  INIT_FAILED: null,

  UPDATE_DATA: null,

  SET_PUBLISH_CANVAS: null,

  SET_WIDGET: null,
})

const PayloadSources = keyMirror({
  SERVER_ACTION: null,
  VIEW_ACTION: null,
})

export { ActionTypes, PayloadSources }
