import * as Ariakit from '@ariakit/react'
import classnames from 'classnames'
import throttle from 'lodash.throttle'
import React from 'react'

import { isSafariDesktop } from '../../../app/shared/isMobile'
import { StatelessDropdown } from '../dropdown'
import { IToolbarContext, ToolbarContext } from './hooks'
import { ToolbarDirection, ToolbarDirectionType, ToolbarTheme, ToolbarThemeType } from './interfaces'

const getToolbarSettings = ({
  direction,
  isWrapped,
  isMobile,
  theme,
}: IToolbarContext): React.HTMLProps<HTMLDivElement> => {
  switch (isWrapped) {
    case true:
      return {
        className: classnames('shrink-0 items-center bg-white dark:bg-gray-800', {
          'flex flex-wrap justify-between': direction === ToolbarDirection.vertical,
          'grid border border-gray-100 dark:border-gray-600 p-1.5 gap-1 border-box rounded-md grid-cols-2':
            FinvizSettings.hasRedesignEnabled,
        }),
      }

    default:
      return {
        className: classnames('flex shrink-0 items-center bg-white dark:bg-gray-800', {
          'p-1.5': !isMobile,
          'space-x-1.5 px-1.5': direction === ToolbarDirection.horizontal && FinvizSettings.hasRedesignEnabled,
          'flex-col overflow-hidden overflow-y-auto [--inset-l:0.5rem] landscape-primary:pl-inset-left':
            direction === ToolbarDirection.vertical,
          'space-y-2': direction === ToolbarDirection.vertical && !isMobile,
          'pb-inset-bottom [--inset-b:0.5rem]': theme !== ToolbarTheme.alternative && !isMobile,
          'w-screen space-x-2 overflow-hidden overflow-x-auto':
            direction === ToolbarDirection.horizontal && theme !== ToolbarTheme.alternative,
          'overflow-x-hidden': theme === ToolbarTheme.alternative,
        }),
      }
  }
}

export interface ToolbarProps {
  /**
   * Toolbar direction
   *
   * @default vertical
   */
  direction?: ToolbarDirectionType

  /**
   * Toolbar buttons theme
   *
   * @default light
   */
  theme?: ToolbarThemeType

  /**
   * Charts mobile design
   *
   * @default false
   */
  isMobile?: boolean

  className?: string
}

export function Toolbar({
  direction = 'vertical',
  theme = 'chipTransparent',
  isMobile = false,
  children,
  className,
}: React.PropsWithChildren<ToolbarProps>) {
  const hasFocusRings = React.useRef(false)
  const toolbarRef = React.useRef<HTMLDivElement>(null)
  const contextProviderValue = React.useMemo(
    () => ({
      direction: ToolbarDirection[direction],
      theme: ToolbarTheme[theme],
      isWrapped:
        ToolbarDirection[direction] === ToolbarDirection.vertical && ToolbarTheme[theme] === ToolbarTheme.alternative,
      isStretched:
        ToolbarDirection[direction] === ToolbarDirection.horizontal && ToolbarTheme[theme] === ToolbarTheme.alternative,
      isMobile,
    }),
    [direction, theme, isMobile]
  )
  const toolbarSettings = getToolbarSettings(contextProviderValue)

  React.useLayoutEffect(() => {
    // Workaround for Safari scrollbar bug https://github.com/finvizhq/charts/issues/1038
    // Safari injects scrollbar after layout render and doesn't trigger reflow
    if (ToolbarTheme[theme] !== ToolbarTheme.alternative && isSafariDesktop()) {
      const forceReflow = throttle(() => {
        if (toolbarRef.current !== null) {
          const originalWidth = toolbarRef.current.style.width
          toolbarRef.current.style.width = '0'
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          toolbarRef.current.offsetWidth
          toolbarRef.current.style.width = originalWidth
        }
      }, 200)

      forceReflow()
      window.addEventListener('resize', forceReflow)

      return () => {
        window.removeEventListener('resize', forceReflow)
      }
    }
  }, [theme])

  return (
    <Ariakit.Menubar
      ref={toolbarRef}
      focusable={false}
      id="toolbar"
      data-testid="toolbar"
      className={classnames(toolbarSettings.className, className)}
      orientation={
        contextProviderValue.isWrapped ? 'both' : contextProviderValue.isStretched ? 'horizontal' : 'vertical'
      }
    >
      <Ariakit.MenuProvider
        showTimeout={100}
        hideTimeout={200}
        placement={contextProviderValue.isStretched ? 'bottom' : 'right-start'}
      >
        <ToolbarContext.Provider value={contextProviderValue}>{children}</ToolbarContext.Provider>
        <StatelessDropdown
          // This is where all the group items will be rendered and the trigger will be assigned programmatically
          trigger={null}
          modal={false}
          backdrop={false}
          rounding={
            contextProviderValue.theme === ToolbarTheme.alternative && !FinvizSettings.hasRedesignEnabled
              ? 'none'
              : undefined
          }
          autoFocusOnShow={() => {
            hasFocusRings.current = !!document.querySelector('[data-focus-visible]')
            return true
          }}
          autoFocusOnHide={(el: HTMLElement) => {
            // Only call focus when we previously had focus rings to prevent sticky focus rings
            if (hasFocusRings.current) el.focus({ preventScroll: true })
            hasFocusRings.current = false
            return false
          }}
        />
      </Ariakit.MenuProvider>
    </Ariakit.Menubar>
  )
}
