import * as Ariakit from '@ariakit/react'
import * as React from 'react'

import { Input, InputComponentProps } from './input'
import { Tooltip, TooltipColor, TooltipProps, TooltipTrigger } from './tooltip'

interface TooltipSettingsProps
  extends Pick<TooltipProps, 'gutter' | 'isPointerEventsEnabled'>,
    Pick<Ariakit.TooltipStoreProps, 'placement'> {
  color?: keyof typeof TooltipColor
}

interface TooltipInputProps extends InputComponentProps {
  /**
   * Optimization to turn off the tooltip functionality if the input isn’t visible on screen
   *
   * @default true
   */
  isInteractive?: boolean

  /**
   * Settings for Tooltip component and useTooltipStore
   */
  tooltipSettings?: TooltipSettingsProps
}

function TooltipInputComponent(
  { tooltipSettings, isInteractive = true, name, children, ...props }: React.PropsWithChildren<TooltipInputProps>,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { color, gutter, placement = 'bottom', isPointerEventsEnabled } = tooltipSettings ?? {}

  const input = <Input {...props} ref={ref} name={name} />

  if (!isInteractive || !children) {
    return input
  }

  return (
    <Ariakit.TooltipProvider placement={placement}>
      <TooltipTrigger state={undefined} hideOnBlur showOnHover={false} render={input} />
      <Tooltip
        state={undefined}
        isPointerEventsEnabled={isPointerEventsEnabled}
        id={name}
        color={color}
        gutter={gutter}
        className="px-2.5"
        hideOnHoverOutside={false}
        data-testid={name?.length ? `${name}-tooltip` : undefined}
      >
        {children}
      </Tooltip>
    </Ariakit.TooltipProvider>
  )
}

export const TooltipInput = React.forwardRef(TooltipInputComponent)
