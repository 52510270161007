import * as React from 'react'

import { ToolbarDirection, ToolbarTheme } from './interfaces'

export interface IToolbarContext {
  direction: ToolbarDirection
  theme: ToolbarTheme
  isWrapped: boolean
  isStretched: boolean
  isMobile: boolean
}

export const ToolbarContext = React.createContext<IToolbarContext>({
  direction: ToolbarDirection.vertical,
  theme: ToolbarTheme.chipTransparent,
  isWrapped: false,
  isStretched: false,
  isMobile: false,
})

export function useToolbarContext() {
  return React.useContext(ToolbarContext)
}
