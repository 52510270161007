import classnames from 'classnames'
import * as React from 'react'
import * as ReactDOM from 'react-dom/server'
import Shepherd from 'shepherd.js'

import {
  BUTTON_BASE_CLASS,
  BUTTON_FOCUS_STYLES,
  ButtonPadding,
  ButtonRounding,
  ButtonSize,
  ButtonTheme,
  getButtonTheme,
} from '../button'
import { StepContent } from './step-content'
import { TourDefinition, TourStep } from './types'

type ExcludesFalse = <T>(x: T | false) => x is T

/**
 * Async load Shepherd module when we need it
 */
let shepherdLibrary: typeof Shepherd
export async function loadShepherdLibrary(setLoading: (state: boolean) => void) {
  if (shepherdLibrary) return shepherdLibrary

  try {
    setLoading(true)
    const shepherdPromise = await import('shepherd.js')
    shepherdLibrary = shepherdPromise.default
    setLoading(false)

    return shepherdLibrary
  } catch {}
}

/**
 * Get buttons based on index in the step array
 */
const buttonClasses = classnames(BUTTON_BASE_CLASS, BUTTON_FOCUS_STYLES, ButtonSize.regular, ButtonRounding.regular)
function getStepButtons(tour: Shepherd.Tour, step: TourStep, index: number, arr: TourStep[]) {
  return [
    {
      action: tour.complete,
      classes: classnames(
        buttonClasses,
        'mr-auto',
        getButtonTheme({ theme: ButtonTheme.light }).button,
        ButtonPadding.regular
      ),
      text: 'Exit',
    },
    index > 0 && {
      action: tour.back,
      classes: classnames(buttonClasses, getButtonTheme({ theme: ButtonTheme.light }).button, ButtonPadding.regular),
      text: step.backButton ?? 'Back',
    },
    {
      classes: classnames(buttonClasses, getButtonTheme({ theme: ButtonTheme.blue }).button, ButtonPadding.regular),
      action: tour.next,
      text: step.nextButton ?? (index === arr.length - 1 ? 'Finish' : 'Next'),
    },
  ].filter(Boolean as unknown as ExcludesFalse)
}

/**
 * Convert tour steps to Shepherd steps
 */
export function processSteps(tour: Shepherd.Tour, steps: TourStep[]): Shepherd.Step.StepOptions[] {
  return steps.map((step, index) => ({
    attachTo: { element: step.target, on: step.placement ?? 'auto' },
    classes: 'z-dialog',
    // Add offset
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: step.offset ?? [0, 10],
          },
        },
      ],
    },
    // Render the body
    text: ReactDOM.renderToString(React.createElement(StepContent, { step })),
    // Add buttons
    buttons: getStepButtons(tour, step, index, steps),
    modalOverlayOpeningRadius: step.overlayRadius ?? 8,
    modalOverlayOpeningPadding: step.overlayPadding ?? 0,
  }))
}

export function getTourKey() {
  return `tour${window.location.pathname.replace(/[/.]/g, '-')}`
}

export function getStepsForUser(tours: TourDefinition[]) {
  const lastFinishedTour = localStorage?.getItem(getTourKey())
  const lastTourIndex = tours.findIndex((tour) => tour.id === lastFinishedTour)
  const allToursViewed = lastTourIndex === tours.length - 1 || tours.slice(lastTourIndex + 1).every((item) => item.skip)
  const toursToView = allToursViewed ? tours : tours.slice(lastTourIndex + 1)
  const userTour = toursToView.reduce(
    (acc, current) =>
      current.skip
        ? acc
        : {
            ...acc,
            id: current.id,
            steps: [...acc.steps, ...current.steps],
          },
    { id: 'tour', steps: [], initialTour: toursToView[0].id }
  )

  return { hasNewTours: !allToursViewed, tour: userTour }
}

export function completeTour(key: string) {
  localStorage?.setItem(getTourKey(), key)
}
