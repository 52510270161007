import throttle from 'lodash.throttle'
import * as React from 'react'

export function getVisualViewport(visualViewport: VisualViewport | null) {
  const width = visualViewport?.width ?? window.innerWidth
  const height = visualViewport?.height ?? window.innerHeight
  const scale = visualViewport?.scale ?? 1

  return {
    top: visualViewport?.offsetTop ?? 0,
    left: visualViewport?.offsetLeft ?? 0,
    scale: Math.round((1 / scale) * 100) / 100,
    width: Math.round(width * scale),
    height: Math.round(height * scale),
    unscaledWidth: width,
    unscaledHeight: height,
  }
}

export function useVisualViewport({ enabled = true, onScroll = true }: { enabled?: boolean; onScroll?: boolean } = {}) {
  const [viewport, setViewport] = React.useState(getVisualViewport(window.visualViewport))

  React.useEffect(() => {
    if (!enabled) return

    const handleChange = throttle((ev: Event) => {
      const viewport = ev.currentTarget as VisualViewport
      if (viewport) {
        setViewport(() => getVisualViewport(viewport))
      }
    }, 50)

    window.visualViewport?.addEventListener('resize', handleChange)
    if (onScroll) window.visualViewport?.addEventListener('scroll', handleChange)
    handleChange({ currentTarget: window.visualViewport } as Event)

    return () => {
      window.visualViewport?.removeEventListener('resize', handleChange)
      window.visualViewport?.removeEventListener('scroll', handleChange)
    }
  }, [enabled, onScroll])

  return React.useMemo(() => ({ viewport, setViewport }), [viewport])
}
