import { Quote } from '../charts-lib'
import './market_sentiment'
import './refresh'

interface Index {
  ticker: string
  timeframe: string
  volume: number[]
  date: number[]
  open: number[]
  high: number[]
  low: number[]
  close: number[]
  lastOpen: null
  lastHigh: null
  lastLow: null
  lastClose: number
  lastVolume: null
  lastDate: number
  prevClose: number
  afterClose: null
  afterChange: null
  relativeVolume: number
}

export type Indices = Record<string, Index>

export function getData(index: string, ticker: string, indices: Indices) {
  return {
    ...indices[index],
    ticker: ticker, // use text?
    timeframe: 'i10',
    instrument: 'stock',
    premarket: false,
    aftermarket: false,
    hasChartEvents: false,
    afterChange: null,
    afterClose: null,
    drawMinutesPerDay: 390,
    marketStartMinutes: 570,
    premarketLengthMinutes: 0,
    aftermarketLengthMinutes: 0,
  }
}

export function updateIndices(indices: Indices) {
  const dowQ = Quote.findByAttribute('ticker', 'DOW')
  if (dowQ) dowQ.updateAttributes(getData('^DJI', 'DOW', indices))

  const nasdaqQ = Quote.findByAttribute('ticker', 'NASDAQ')
  if (nasdaqQ) nasdaqQ.updateAttributes(getData('^IXIC', 'NASDAQ', indices))

  const sap500Q = Quote.findByAttribute('ticker', 'S&P 500')
  if (sap500Q) sap500Q.updateAttributes(getData('^GSPC', 'S&P 500', indices))

  const rutQ = Quote.findByAttribute('ticker', 'RUSSELL 2000')
  if (rutQ) rutQ.updateAttributes(getData('^RUT', 'RUSSELL 2000', indices))

  if (!dowQ || !nasdaqQ || !sap500Q || !rutQ) {
    // Temporary code below
    // additional info gaining for https://finvizcom.sentry.io/issues/3963831382/?project=33153&referrer=issue-stream&statsPeriod=14d&stream_index=0
    window.Sentry?.captureMessage('indices-data.ts updateIndices', {
      extra: {
        allAvailableTickers: Quote.all()
          .reduce((acc: string[], cur: { ticker: string }) => {
            acc.push(cur.ticker)
            return acc
          }, [])
          .join(','),
      },
    })
  }
}
