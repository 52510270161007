import { Box } from '../box'
import { Heading, Paragraph } from '../typography'
import { TourStep } from './types'

export function StepContent({ step }: { step: TourStep }) {
  return (
    <Box className="w-64 p-4 pb-14">
      {step.title && (
        <Heading level={5} className="mb-2">
          {step.title}
        </Heading>
      )}
      {step.body && <Paragraph>{step.body}</Paragraph>}
    </Box>
  )
}
