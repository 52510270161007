import classnames from 'classnames'
import React from 'react'

interface GridLayoutProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * Number of columns
   */
  columns?: number

  /**
   * Number of rows
   */
  rows?: number

  /**
   * Whether or not a border is rendered between and around items. eg. charts
   * on HP won’t have a border
   *
   * @default false
   */
  border?: boolean
}

export const ChartGridCell = React.forwardRef<HTMLDivElement, ChartGridCellProps>(
  ({ children, gridArea, className, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={classnames(className, 'flex flex-1 flex-col overflow-hidden')}
      style={{ gridArea }}
    >
      {children}
    </div>
  )
)

interface ChartGridCellProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * Css grid-area value
   */
  gridArea?: string
}

export function GridLayout({ columns = 1, rows = 1, border = false, children, className, ...props }: GridLayoutProps) {
  return (
    <div
      {...props}
      className={classnames(className, 'grid grow gap-px', {
        'border border-gray-100 bg-gray-100 dark:border-gray-700 dark:bg-gray-700': border,
      })}
      style={{ gridTemplateColumns: `repeat(${columns}, 1fr)`, gridTemplateRows: `repeat(${rows}, 1fr)` }}
    >
      {children}
    </div>
  )
}

interface ChartGridProps extends GridLayoutProps {
  isResizable?: boolean
}

export const ChartGrid: React.FC<ChartGridProps> = (props) => <GridLayout {...props} />
