import { EventEmitter } from 'events'

class Store extends EventEmitter {
  static CHANGE_EVENT = 'change'

  emitChange() {
    this.emit(Store.CHANGE_EVENT)
  }

  addChangeListener(callback: (...args: any[]) => void) {
    this.on(Store.CHANGE_EVENT, callback)
  }

  removeChangeListener(callback: (...args: any[]) => void) {
    this.removeListener(Store.CHANGE_EVENT, callback)
  }
}

export default Store
