import * as Ariakit from '@ariakit/react'
import classNames from 'classnames'

export function TabList(props: Ariakit.TabListProps) {
  return (
    <Ariakit.TabList
      {...props}
      className={classNames(props.className, 'flex shadow-[inset_0_-1px_0] shadow-gray-100 dark:shadow-gray-700')}
    />
  )
}
