import classnames from 'classnames'
import React, { ForwardedRef, useCallback, useEffect, useState } from 'react'

import { Input, InputComponentProps } from '../input'
import { ColorPreview } from './color-preview'
import { ColorPickerProps } from './picker'
import * as colorUtil from './util'

interface PickerInputProps extends Omit<InputComponentProps, 'onChange'> {
  color: ColorPickerProps['color']
  onChange: ColorPickerProps['onChange']

  /*
   * Is color preview square visible
   *
   * @default true
   * */
  hasColorPreview?: boolean
}
export const PickerInput = React.forwardRef(
  (
    { color, inputClass, hasColorPreview = true, onChange, ...props }: PickerInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [colorInput, setColorInput] = useState(colorUtil.removeHashSymbol(color))
    const isRgba = colorUtil.getIsRgb(colorInput)

    useEffect(() => {
      setColorInput(colorUtil.removeHashSymbol(color))
    }, [color])

    const handleColorInputChange = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        const value = colorUtil.removeHashSymbol(event.currentTarget.value)
        const isRgba = value.startsWith('rgba')
        const colorValue = isRgba ? value : `#${value}`

        setColorInput(value)
        if ((value.length >= 6 || (isRgba && value.length >= 16)) && colorUtil.isValidColor(colorValue)) {
          onChange(colorValue, colorUtil.hexStringToHSVA(colorValue))
        }
      },
      [onChange]
    )
    return (
      <Input
        {...props}
        ref={ref}
        value={colorInput}
        inputClass={classnames(
          'text-left',
          {
            'pl-6.5': !hasColorPreview && !isRgba,
            'pl-1': !hasColorPreview && isRgba,
            'pl-12': hasColorPreview && !isRgba,
            'pl-8': hasColorPreview && isRgba,
          },
          inputClass
        )}
        leftContent={
          <div className={classnames('flex items-center', { 'ml-4 pl-1': hasColorPreview })}>
            {hasColorPreview && (
              <div className="h-5 w-5">
                <ColorPreview color={color} className="rounded" />
              </div>
            )}
            {!isRgba && <span className={classnames({ 'ml-2': hasColorPreview })}>#</span>}
          </div>
        }
        onChange={handleColorInputChange}
      />
    )
  }
)
