import { useCallback, useMemo } from 'react'

import { DraggablePicker, Interaction } from './draggable'
import { Pointer } from './pointer'
import { HSVA, hsvaToHEXString } from './util'

interface HueSaturationProps {
  color: HSVA
  onChange: (color: HSVA) => void
}

function getPositionFromHSLA(color: HSVA) {
  return {
    left: color.h / 360,
    top: 1 - color.s,
  }
}

export function HueSaturation({ color, onChange }: HueSaturationProps) {
  const position = useMemo(() => getPositionFromHSLA(color), [color])

  const colorPreview = useMemo(() => hsvaToHEXString({ h: color.h, s: color.s, v: 1, a: 1 }), [color.h, color.s])

  const changeSaturation = useCallback(
    (pos: Interaction) =>
      onChange({
        h: Math.round(pos.left * 360),
        s: 1 - pos.top,
        v: 1,
        a: color.a,
      }),
    [color.a, onChange]
  )

  return (
    <DraggablePicker
      onMove={changeSaturation}
      onKey={changeSaturation}
      aria-label="Color"
      className="flex grow flex-col"
    >
      <div className="flex h-full flex-1 flex-col overflow-hidden rounded-md bg-gradient-hue">
        <div className="h-full flex-1 bg-white-fade-in" />
      </div>
      <Pointer top={position.top} left={position.left} color={colorPreview} />
    </DraggablePicker>
  )
}
