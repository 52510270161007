import * as Ariakit from '@ariakit/react'
import classNames from 'classnames'
import * as React from 'react'

import { usePrevious } from '../../hooks/use-previous'
import { usePopoverPlacement } from '../../hooks/usePopoverPlacement'
import { blurWithoutScroll, focusWithoutScroll, preventDefault } from '../../util'

enum DropdownRounding {
  none = '',
  regular = 'rounded-lg',
}

enum DropdownTheme {
  none = '',
  default = 'border border-primary bg-primary',
}

interface StatelessDropdownProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'ref'>,
    Pick<Ariakit.MenuProps, 'hideOnInteractOutside'> {
  /**
   * Trigger button for the dropdown
   */
  trigger: JSX.Element | null

  /**
   * Disable interaction with other page elements when the dropdown is open
   *
   * @default true
   */
  modal?: boolean

  /**
   * When true, the dropdown will show when dropdown is hovered. Combine with
   * `backdrop={false}` to make the popover hide on mouse out
   */
  showOnHover?: boolean

  /**
   * Placement of the dropdown
   *
   */
  placement?: Ariakit.MenuProviderProps['placement']

  /**
   * Orientation in which the items are laid out. Changes which arrows control next/prev item
   *
   * @default vertical
   */
  orientation?: 'vertical' | 'horizontal'

  /**
   * Set rounding for the dropdown list
   *
   * @default regular
   */
  rounding?: keyof typeof DropdownRounding

  /**
   * Dropdown theme
   *
   * @default default
   */
  theme?: keyof typeof DropdownTheme

  /**
   * The amount of space between button and its popover
   *
   * @default 4
   */
  gutter?: number

  /**
   * Show backdrop on the dialog. Provide your own element if you want to add background or animation
   *
   */
  backdrop?: false | JSX.Element

  /**
   * Do not render the items when the popover is not visible
   *
   * @default true
   */
  unmountOnHide?: boolean

  /**
   * If true, the dropdown will overlay the trigger if necessary instead of going
   * out of bounds and overflowing
   */
  overlap?: boolean

  /**
   * Callback before the menu starts hiding. Event can be prevented which keeps the menu open
   * This callback isn’t called on backdrop click. Use `backdrop={<div onMouseDown={…} />}` to react on backdrop clicks
   */
  onClose?: (ev: Event) => void

  /**
   * Used to provide virtual anchor, useful for context menu, etc
   */
  getAnchorRect?: () => { x: number; y: number }

  /**
   * Set which element gets focus when the dialog visibility changes. If `false`, the focus stays on the previous element.
   * Defaults to focusing without scrolling the page or keeping the focus on trigger if input-like.
   */
  autoFocusOnShow?: false | ((element: HTMLElement) => boolean)

  /**
   * Set which element gets focus when the dialog visibility changes. If `false`, the focus stays on the previous element.
   * Defaults to focusing without scrolling the page or keeping the focus on trigger if input-like.
   */
  autoFocusOnHide?: false | ((element: HTMLElement) => boolean)

  /**
   * Callback which is called when the dialog opens and all animations complete
   */
  onFullyOpen?: () => void

  /**
   * Callback which is called when the dialog closes and all animations complete
   */
  onFullyClosed?: () => void
}

export const StatelessDropdown = React.forwardRef(function DropdownComponent(
  {
    trigger,
    modal = true,
    rounding = 'regular',
    theme = 'default',
    gutter,
    unmountOnHide = true,
    children,
    backdrop,
    showOnHover,
    hideOnInteractOutside,
    autoFocusOnShow,
    autoFocusOnHide,
    onFullyOpen,
    onFullyClosed,
    ...props
  }: React.PropsWithChildren<StatelessDropdownProps>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const store = Ariakit.useMenuContext()
  const { placement, zIndex, updatePosition } = usePopoverPlacement(store)
  const isOpen = store?.useState('open')
  const [isFullyOpen, setIsFullyOpen] = React.useState(false)
  const isMounted = store?.useState('mounted')
  const wasMounted = usePrevious(isMounted)
  const menuGutter = gutter ?? (store?.parent ? 6 : 4)

  React.useEffect(() => {
    if (wasMounted && !isMounted) {
      onFullyClosed?.()
    }
    // We want to call it only on isMounted change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted])

  return (
    <>
      {trigger && (
        <Ariakit.MenuButton
          ref={ref}
          // @ts-expect-error - TS is correct here MenuButton doesn’t have `active` prop,
          // but we want to pass it to the rendered component
          active={store?.parent ? undefined : isOpen}
          showOnHover={showOnHover}
          render={trigger}
        />
      )}
      <Ariakit.Menu
        {...props}
        focusable={false}
        // Render in a portal to escape overflow
        portal
        modal={modal || showOnHover === false}
        hideOnHoverOutside={showOnHover}
        hideOnInteractOutside={hideOnInteractOutside}
        preventBodyScroll={false}
        // Do not keep the children mounted when the popover is closed
        unmountOnHide={unmountOnHide}
        // Space between button and popover
        gutter={menuGutter}
        // Manage the focus ourselves so we can prevent scrolling when focusing the disclosure element
        // We can’t focus if using `showOnHover`.
        autoFocusOnShow={autoFocusOnShow ?? focusWithoutScroll}
        autoFocusOnHide={autoFocusOnHide ?? blurWithoutScroll}
        // Custom event prevents scrolling issues
        // See website PR #1968
        backdrop={backdrop ?? <div onMouseDown={preventDefault} data-testid="dropdown-backdrop" />}
        // Update zIndex when popover position changes
        updatePosition={updatePosition}
        // E2E tests don’t wait for transitions to complete and it could lead to bugs with other popover components (ResizeObserver loop exceeded)
        // or visual diffs due a screenshot being captured while the dropdown still in transition
        // If you encounter this problem, just do `cy.get('[data-fully-open=true]').should('exist')` before continuing to wait for the dropdown
        data-fully-open={isFullyOpen}
        className={classNames(
          props.className,
          zIndex,
          DropdownRounding[rounding],
          DropdownTheme[theme],
          'custom-scrollbar absolute flex flex-col overflow-hidden shadow-modal outline-none dark:shadow-modal-dark',
          // These are set by popper, we just need to make sure we don’t encroach on the safe insets
          'min-w-[--popover-anchor-width] max-w-[--popover-available-width]',
          // Animations
          'opacity-0 transition duration-[250ms] data-[enter]:translate-y-0 data-[enter]:opacity-100',
          {
            // When in combobox mode, the padding is smaller so we can render a gradient under the combobox input.
            // Change the custom scrollbar offset so the top of the first item fits the top of our scrollbar
            '[--scrollbar-radius:0]': DropdownRounding[rounding] === DropdownRounding.none,
            // Direction based styles
            'max-h-[calc(var(--popover-available-height)-env(safe-area-inset-bottom))] motion-safe:-translate-y-1':
              placement?.startsWith('bottom'),
            'max-h-[calc(var(--popover-available-height)-env(safe-area-inset-top))] motion-safe:translate-y-1':
              placement?.startsWith('top'),
          }
        )}
        onTransitionEnd={(ev) => {
          if (ev.target === ev.currentTarget && isOpen) {
            setIsFullyOpen(true)
            onFullyOpen?.()
          }
        }}
      >
        <div
          tabIndex={-1}
          className="flex max-h-[500px] grow scroll-py-1.5 flex-col overflow-y-auto overscroll-contain p-1.5 outline-none"
        >
          {children}
        </div>
      </Ariakit.Menu>
    </>
  )
})

export interface DropdownProps extends StatelessDropdownProps {
  state?: Ariakit.MenuStore
}

export const Dropdown = React.forwardRef(
  (
    { state, placement, orientation = 'vertical', ...props }: React.PropsWithChildren<DropdownProps>,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => (
    <Ariakit.MenuProvider store={state} focusLoop virtualFocus placement={placement} orientation={orientation}>
      <StatelessDropdown ref={ref} {...props} />
    </Ariakit.MenuProvider>
  )
)
