import { MapDataNode } from '../types'
import AppDispatcher from './appDispatcher'
import { ActionTypes } from './constants'
import Store from './store'

let _hoveredNode: MapDataNode
let _mapNodeId: string
let _sparklinesData: Record<string, number[]> = {}
let _dataVersion: number
let _isWidget = false
let _getPublishCanvas: () => HTMLCanvasElement

class MapStore extends Store {
  getHoveredNode(mapNodeId: string) {
    if (mapNodeId === _mapNodeId) return _hoveredNode
    return undefined
  }

  getSparklines() {
    return _sparklinesData
  }

  getDataVersion() {
    return _dataVersion
  }

  isWidget() {
    return _isWidget
  }

  getPublishCanvas() {
    return _getPublishCanvas()
  }
}

var mapStore = new MapStore()

// @ts-ignore
mapStore.dispatchToken = AppDispatcher.register(function (payload: any) {
  var action = payload.action

  switch (action.type) {
    case ActionTypes.RENDER_TREEMAP:
      mapStore.emitChange()
      break

    case ActionTypes.SET_HOVERED_NODE:
      if (action.node !== _hoveredNode) {
        _hoveredNode = action.node
        _mapNodeId = action.mapNodeId
        mapStore.emitChange()
      }
      break

    case ActionTypes.CHANGE_TRANSLATE:
      // @ts-ignore
      _hoveredNode = undefined
      mapStore.emitChange()
      break

    case ActionTypes.LOAD_SPARKLINES_STARTED:
      mapStore.emitChange()
      break

    case ActionTypes.LOAD_SPARKLINES_COMPLETED:
      _sparklinesData = { ..._sparklinesData, ...action.data }
      mapStore.emitChange()
      break

    case ActionTypes.RESET_SPARKLINE_DATA:
    case ActionTypes.LOAD_SPARKLINES_FAILED:
      _sparklinesData = {}
      mapStore.emitChange()
      break

    case ActionTypes.UPDATE_DATA:
      _dataVersion = action.version
      mapStore.emitChange()
      break

    case ActionTypes.SET_PUBLISH_CANVAS:
      _getPublishCanvas = action.getPublishCanvas
      mapStore.emitChange()
      break

    case ActionTypes.SET_WIDGET:
      _isWidget = action.isWidget
      mapStore.emitChange()
      break
  }
})

export default mapStore
