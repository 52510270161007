import classnames from 'classnames'
import { HTMLProps, ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import { Label } from './typography'

export enum RangeSize {
  small = 'sm:h-6', // 24px height
  regular = 'sm:h-7', // 28px height
  medium = 'sm:h-8', // 32px height
  large = 'sm:h-9', // 36px height
}

type InputProps = HTMLProps<HTMLInputElement>

interface RangeSliderProps extends Omit<InputProps, 'label' | 'size'> {
  /**
   * Label which is rendered above the select
   */
  label?: ReactNode

  /**
   * Range size. All inputs have forced height on mobile to avoid zoom
   *
   * @default regular
   */
  size?: keyof typeof RangeSize

  /**
   * Element which will be rendered on the left side of the slider
   */
  leftContent?: ReactNode

  /**
   * Element which will be rendered on the right side of the slider
   */
  rightContent?: ReactNode

  /**
   * Class name for the wrapper element
   */
  className?: string

  /**
   * Class name for the input element
   */
  inputClassName?: string

  /**
   * Min value for the input
   *
   * @default 0
   */
  min?: number

  /**
   * Max value for the input
   *
   * @default 100
   */
  max?: number

  /**
   * Step value for the input
   *
   * @default 1
   */
  step?: number

  /**
   * Current value
   */
  value: number

  /**
   * Callback when value changes
   */
  onChange: InputProps['onChange']
}

export function RangeSlider({
  label,
  size = 'regular',
  leftContent,
  rightContent,
  min = 0,
  max = 100,
  step = 1,
  className,
  inputClassName,
  ...props
}: RangeSliderProps) {
  const [hasTrack, setHasTrack] = useState(false)
  const trackRef = useRef<HTMLDivElement>(null)
  const thumbRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    setHasTrack(Boolean(trackRef.current && thumbRef.current))
  }, [])

  const thumbLeft = useMemo(() => {
    const track = trackRef.current
    const thumb = thumbRef.current
    const valuePercent = (props.value - min) / (max - min)

    if (!hasTrack || !track || !thumb) return

    const trackBox = track.getBoundingClientRect()
    const thumbBox = thumb.getBoundingClientRect()

    return ((valuePercent * (trackBox.width - thumbBox.width)) / trackBox.width) * 100
  }, [min, max, props.value, hasTrack])

  return (
    <Label title={label} className={className}>
      <div className={classnames(RangeSize[size], 'group flex h-8 items-center space-x-3')}>
        {leftContent}

        <div ref={trackRef} className="relative grow">
          <div className="group w-full">
            <div
              className={classnames('h-0.5 overflow-hidden rounded bg-gray-200 dark:bg-gray-600', {
                'opacity-0': !hasTrack,
              })}
            >
              <div
                className="h-full origin-top-left bg-blue-400 group-hover:bg-blue-500"
                style={{ width: `${thumbLeft}%` }}
              />
            </div>

            <span
              ref={thumbRef}
              className={classnames(
                'absolute top-1/2 -mt-3 h-6 w-6 rounded-full border-2 border-blue-400 bg-white shadow transition-transform duration-200 group-hover:scale-110 group-hover:border-blue-500 dark:bg-gray-600 sm:-mt-2 sm:h-4 sm:w-4',
                { 'opacity-0': !hasTrack }
              )}
              style={{ left: `${thumbLeft}%` }}
            />
          </div>

          <input
            {...props}
            type="range"
            min={min}
            max={max}
            step={step}
            className={classnames(inputClassName, 'absolute top-0 h-full w-full cursor-pointer opacity-0')}
          />
        </div>

        {rightContent}
      </div>
    </Label>
  )
}
