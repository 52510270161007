import { useCallback, useMemo } from 'react'

import { DraggablePicker, Interaction } from './draggable'
import { Pointer } from './pointer'
import { HSVA, hsvaToHEXString } from './util'

interface LightnessProps {
  color: HSVA
  onChange: (color: HSVA) => void
}

export function Lightness({ color, onChange }: LightnessProps) {
  const changeLightness = useCallback((pos: Interaction) => onChange({ ...color, v: 1 - pos.left }), [onChange, color])

  const colorPreview = useMemo(
    () => hsvaToHEXString({ h: color.h, s: color.s, v: color.v, a: 1 }),
    [color.h, color.s, color.v]
  )

  const gradientPreview = useMemo(() => {
    const gradientFrom = hsvaToHEXString({ h: color.h, s: color.s, v: 1, a: 1 })
    const gradientTo = hsvaToHEXString({ h: color.h, s: color.s, v: 0, a: 1 })

    return `linear-gradient(90deg, ${gradientFrom}, ${gradientTo})`
  }, [color.h, color.s])

  return (
    <DraggablePicker onMove={changeLightness} onKey={changeLightness} aria-label="Lightness">
      <div className="flex h-4 rounded-md" style={{ backgroundImage: gradientPreview }} />
      <Pointer top={0.5} left={1 - color.v} color={colorPreview} />
    </DraggablePicker>
  )
}
