import * as React from 'react'

export type NotificationContextType = ReturnType<typeof useNotification>

export enum NotificationPosition {
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
}

export interface NotificationOptions {
  position?: NotificationPosition
  inline?: boolean
}

export const NotificationContext = React.createContext<{
  show: (element: JSX.Element, options?: NotificationOptions) => void
  hide: () => void
}>({
  show: () => {},
  hide: () => {},
})

export function useNotification() {
  return React.useContext(NotificationContext)
}
